import { Router } from "@reach/router";
import React from "react";

import { LoadingContainer } from "@interactive-investor/onestack-web-shared";

import PasswordResetSuccess from "../../contexts/Auth/components/PasswordResetSuccess";
import UnblockIP from "../../contexts/Auth/components/UnblockIP";
import VerifyEmailSuccess from "../../contexts/Auth/components/VerifyEmailSuccess";

import Layout from "../../components/layout";
import { Route } from "../../helpers/routing";

export interface Auth0PageProps {
  location: any;
}

export interface Auth0PageState {
  mounted: boolean;
}

class Auth0Page extends React.Component<Auth0PageProps, Auth0PageState> {
  constructor(props: Auth0PageProps) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    // We want this to be a client-only component, so only render some things if we are mounted
    if (!this.state.mounted) {
      return (
        <LoadingContainer title="Authentication" message="Please wait..." />
      );
    }

    return (
      <Layout>
        <Router>
          <Route
            Component={VerifyEmailSuccess}
            path="/auth-redirect/verify-email"
          />
          <Route Component={UnblockIP} path="/auth-redirect/unblock-ip" />
          <Route
            Component={PasswordResetSuccess}
            path="/auth-redirect/password-reset"
          />
        </Router>
      </Layout>
    );
  }
}

export default Auth0Page;
