import {
  colors,
  EmotionStyles,
  grid,
} from "@interactive-investor/onestack-web-shared";

export const styles: EmotionStyles = {
  CTAButtonText: {
    color: colors.white,
    paddingRight: grid.spacing.sm,
    paddingLeft: grid.spacing.sm,
    "& :hover": {
      textDecoration: "none",
    },
  },
  CTAButton: {
    marginTop: grid.spacing.sm,
  },
  InfoMessageContainer: {
    marginTop: grid.spacing.md,
  },
};
