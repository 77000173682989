import * as React from "react";

import {
  Button,
  InfoMessage,
  OneSiteLink,
  SEOMeta,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "..";
import { AuthContextInjectedProps } from "../interfaces/context";

import { AuthVerifyEmailSuccessProps } from "../interfaces/props";

import { styles } from "./styles";

class AuthVerifyEmailSuccess extends React.Component<
  AuthVerifyEmailSuccessProps & AuthContextInjectedProps
> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const { auth } = this.props;
    return (
      <React.Fragment>
        <SEOMeta
          meta={{
            canonical_url: "/auth-redirect/verify-email",
            description: "",
            robots: "noindex, follow",
            title: "Your email address has been confirmed",
          }}
        />
        <div className="GridRow">
          <div className="GridCell-xs-12 GridCell-offset-md-2 GridCell-md-8">
            <div css={styles.InfoMessageContainer}>
              <InfoMessage heading="your email address has been confirmed">
                <p>
                  Now that you have confirmed your email address your account is
                  now ready and you can log in to your research account using
                  the button below, or you can return to our{" "}
                  <OneSiteLink to="/" title="Home Page">
                    home page
                  </OneSiteLink>
                  .
                </p>
                <p>
                  By logging in here you will be taken to your new{" "}
                  <strong>virtual portfolio</strong>, which you can start using
                  straight away.
                </p>
              </InfoMessage>
              <Button
                cssStyles={[styles.CTAButton]}
                type="primary"
                size="large"
                onClick={() => {
                  auth.loginFree();
                }}
                title="Log In"
              >
                log in
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withAuth(AuthVerifyEmailSuccess);
