import React from "react";

import {
  Button,
  InfoMessage,
  OneSiteLink,
  SEOMeta,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "..";
import { AuthContextInjectedProps } from "../interfaces/context";
import { AuthPasswordResetSuccessProps } from "../interfaces/props";

import { styles } from "./styles";

class AuthPasswordResetSuccess extends React.Component<
  AuthPasswordResetSuccessProps & AuthContextInjectedProps
> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const { auth } = this.props;

    return (
      <React.Fragment>
        <SEOMeta
          meta={{
            canonical_url: "/auth-redirect/password-reset",
            description: "",
            robots: "noindex, follow",
            title: "Your password has been updated",
          }}
        />
        <div className="GridRow">
          <div className="GridCell-xs-12 GridCell-offset-md-2 GridCell-md-8">
            <div css={styles.InfoMessageContainer}>
              <InfoMessage heading="your password has been updated">
                <p>
                  Now that you have confirmed your new password for your
                  research account, you can log in using the button below, or
                  you can return to our{" "}
                  <OneSiteLink to="/" title="Home Page">
                    home page
                  </OneSiteLink>
                  .
                </p>
                <p>
                  By logging in here you will be taken to your{" "}
                  <strong>virtual portfolio</strong>, which you can continue
                  using straight away.
                </p>
              </InfoMessage>
              <Button
                cssStyles={[styles.CTAButton]}
                type="primary"
                size="large"
                onClick={() => {
                  auth.loginFree();
                }}
                title="Log In"
              >
                log in
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withAuth(AuthPasswordResetSuccess);
